<template>
  <div>
    <b-card title=" We deliver scent marketing solutions to all kinds of businesses and industries.">
      <b-card-text>
        <p>
          One of the most creative scent marketing agencies in the Middle East, with a team of highly skilled professionals,
          <br>
          we plan and install a perfect scent marketing strategy and solution for you to engage your customers with your brand.
          <br>Instead of just offering our clients with one solution like scent systems for their retail stores or malls,
          <br>we also consider how clients can install their own scent to build unique and strong brand identity.
        </p>
      </b-card-text>
      <b-card-text>For More Information please visit our <b-link
          href="https://www.ahlaajaw.com/"
          target="_blank">
       <b>Official Website</b>
      </b-link>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
